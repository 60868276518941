.navbar {
    height: 60px;
    background-color: #fef7e5;
    position: relative;
}

.menu-icon {
    display: none;
    position: absolute;
    top: 50%;
    right: 0;
    width: 30px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    img {
        -webkit-filter: invert(1);
        filter: invert(1);
    }
}

.nav-elements ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    list-style-type: none;
    margin: 0px;
}

.nav-elements ul li:not(:last-child) {
    margin-right: 30px;
}

.nav-elements ul a {
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    text-decoration: none;
    position: relative;
    text-transform: uppercase;
}

.nav-elements ul a:hover {
    color: #02ffff;
}

.nav-elements ul a:hover::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #02ffff;
}

nav.navbar {
    position: absolute;
    top: 60px;
    left: 50%;
    width: 1320px;
    max-width: calc(100% - 10vw);
    z-index: 3;
    height: 115px;
    background-color: transparent;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    .logo {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 10vw;
        flex: 0 0 10vw;
        max-width: 140px;
        min-width: 80px;
    }

    .navbar_inner {
        width: 100%;
        position: relative;
    }

    .balancebox {
        ul {
            margin: 0px;
            padding: 0px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            list-style: none;

            li {
                margin-left: 15px;
                font-weight: 400;

                strong {
                    font-weight: bold;
                    margin-right: 10px;
                }

                span {
                    background-color: #02ffff;
                    color: #000000;
                    display: block;
                    padding: 10px 15px;
                    border-radius: 50px;
                    cursor: pointer;
                }
            }
        }
    }
}

.logoDesktop {
    top: 54px;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);

    img {
        width: 100%;
        max-width: 120px;
        padding: 5px;
        -webkit-transform: scale(1.5);
        -ms-transform: scale(1.5);
        transform: scale(1.5);
    }

    h2 {
        // font-family: "defiworld-Bold1";
        font-size: 42px;
        color: #3df9ff;
    }

    ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        font-family: "defiworld-Bold1";
        margin: 0px;
        padding: 0px;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    
    ul.xy{
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }

    ul li {
        list-style: none;
        letter-spacing: 6px;
        font-size: 32px;
        -webkit-animation: ani 1.2s linear infinite;
        animation: ani 1.2s linear infinite;
    }

    ul li:nth-child(1) {
        -webkit-animation-delay: .2s;
        animation-delay: .2s;
    }

    ul li:nth-child(2) {
        -webkit-animation-delay: .6s;
        animation-delay: .6s;
    }

    ul li:nth-child(3) {
        -webkit-animation-delay: .8s;
        animation-delay: .8s;
    }

    ul li:nth-child(4) {
        -webkit-animation-delay: 1.0s;
        animation-delay: 1.0s;
    }

    ul li:nth-child(5) {
        -webkit-animation-delay: 1.4s;
        animation-delay: 1.4s;
    }

    ul li:nth-child(6) {
        -webkit-animation-delay: 1.8s;
        animation-delay: 1.8s;
    }

    ul li:nth-child(7) {
        -webkit-animation-delay: 1.12s;
        animation-delay: 1.12s;
    }

    ul li:nth-child(8) {
        -webkit-animation-delay: 1.16s;
        animation-delay: 1.16s;
    }

    ul li:nth-child(9) {
        -webkit-animation-delay: 1.20s;
        animation-delay: 1.20s;
    }

    @-webkit-keyframes ani {
        0% {
            text-shadow: 2px 2px 10px #50dbff
        }

        90% {
            text-shadow: 2px 2px 20px #50dbff;
        }

        100% {
            text-shadow:0 0 7px #3df9ff, 0 0 20px #3df9ff;
        }
    }

    @keyframes ani {
        0% {
            text-shadow: 2px 2px 10px #50dbff
        }

        90% {
            text-shadow: 2px 2px 20px #50dbff;
        }

        100% {
            text-shadow:0 0 7px #3df9ff, 0 0 20px #3df9ff;
        }
    }
}

@media (max-width: 1180px) {
    .nav-elements ul {
        padding: 0;
    }

    .nav-elements ul li:not(:last-child) {
        margin-right: 15px;
    }

    .nav-elements ul a {
        font-size: 14px;
    }
}

@media (max-width: 1024px) {
    .nav-elements ul li:not(:last-child) {
        margin-right: 30px;
    }

    nav.navbar .balancebox ul li {
        margin-left: 4px;
        font-size: 14px;
    }

}

@media (max-width: 992px) {
    .balancebox {
        margin-right: 45px;
        margin-left: auto;
    }

    nav.navbar {
        top: 20px;
    }

    .menu-icon {
        display: block;
        cursor: pointer;
    }

    .nav-elements {
        position: absolute;
        right: 0px;
        top: 100%;
        width: 100%;
        height: auto;
        -webkit-transition: all 0.3s ease-in;
        -o-transition: all 0.3s ease-in;
        transition: all 0.3s ease-in;
        overflow: hidden;
        max-width: 320px;
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
        -webkit-transform-origin: top right;
        -ms-transform-origin: top right;
        transform-origin: top right;
    }

    .nav-elements.active {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }

    .nav-elements ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 0;
        margin: 0;
        background-color: #02ffff;
        border-radius: 5px;
    }

    .nav-elements ul li {
        margin-right: 0px;
    }

    .nav-elements ul li:not(:last-child) {
        margin-right: 0px;
    }

    .nav-elements ul li a {
        padding: 15px;
        display: block;
        color: #000000;
        font-weight: bold;

        &:hover {
            color: #000000;
        }
    }
}

@media (max-width: 520px) {

    .header_component_center {
        height: 100%;

        &::after {
            height: 51.6%;
        }

        &::before {
            height: 51.6%;
        }
    }

    nav.navbar .balancebox ul li {
        margin-left: 4px;
        font-size: 14px;
    }

    nav.navbar .balancebox ul li span {
        padding: 8px 8px;
        font-size: 14px;
    }

    nav.navbar .balancebox ul li strong {
        margin-right: 5px;
    }

    nav.navbar .balancebox ul li:nth-last-child(1) {
        margin-left: 8px;

        span.logoutSn {
            font-size: 0px;
            padding: 6px;
            width: 32px;
            height: 32px;
        }

        svg {
            width: 17px;
            height: 17px;
            display: block;
        }
    }
}

@media (max-width: 390px) {
    .menu-icon {
        right: 10px;
        width: 20px;
    }

    nav.navbar {
        top: 0px;
    }

    .publicSale .infobox h1 {
        font-size: 38px;
    }

    nav.navbar .balancebox ul li {
        margin-left: 4px;
        font-size: 10px;
    }

    nav.navbar .logo {
        max-width: 100px;
        min-width: 75px;
    }
}