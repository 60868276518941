.statisics {
  margin: 0 0 60px;
}
.statisics .boxinner {
  height: 100%;
}
.statisics .boxinner h2 {
  display: block;
}
.statisics .boxinner .box {
  position: relative;
  text-align: center;
  border-radius: 5px;
}
.statisics .boxinner .box h3 {
  color: #50dbff;
}
.statisics .boxinner .box p {
  margin: 0 0 15px;
}
.statisics .boxinner .box strong {
  margin: 0 0 15px;
  display: block;
  color: #125d60;
}
/* .statisics .boxinner.rightinfo .box {
  border-color: #135e61;
  box-shadow: none;
  position: relative;
} */
.statisics .boxinner.rightinfo .box strong {
  color: #ffffff;
}
.statisics .copyReferralLink {
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .statisics .boxinner h2 {
    text-align: center;
  }
  .statisics .boxinner.leftinfo {
    margin-bottom: 30px;
  }
}/*# sourceMappingURL=Statisics.css.map */