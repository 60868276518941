@media (min-width: 1700px) {
  header {
    height: 210px !important;
  }
}

@media (min-width: 1400px) {
  .header_component_uper {
    height: 53%;
    // width: 75%;
    // left: 12.5%;
  }

  .header_component_center {
    top: -1px;
    left: 35%;
    width: 30%;
    -webkit-transform: scale(1.05) perspective(300px) rotateX(315deg);
    transform: scale(1.05) perspective(300px) rotateX(315deg);
    z-index: -2;
  }

  .header_component_border_outer {
    position: absolute;
    left: 13%;
    height: 25%;
  }

  .header_component_border_inner {
    position: absolute;
    height: 50%;
  }
}


@media (max-width: 1024px) {
  .header_component_uper {
    -webkit-transform: scale(1.05) perspective(300px) rotateX(343deg);
    transform: scale(1.05) perspective(300px) rotateX(343deg);
  }

  .header_component_center::before {
    height: 48.6%;
  }

  .header_component_center::after {
    height: 48.6%;
  }

  .header_component_border_outer::before {
    -webkit-transform: skewX(200deg);
    -ms-transform: skewX(200deg);
    transform: skewX(200deg);
    left: 12%;
  }

  .header_component_border_outer::after {
    right: 12%;
  }
}


@media only screen and (max-width: 992px) {
  .main {
    padding: 50px 2vw 5vw;
  }

  .oc_btn {
    padding: 10px 10px;
    min-width: auto;
    font-size: 14px;
  }
}

@media (max-width: 786px) {
  .header_component_border_outer {
    &::before {
      display: none;
    }

    &::after {
      display: none;
    }
  }
}

@media only screen and (min-width: 767px) {
  .md\:justify-start {
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 767px) {
  .buttonGrp {
    text-align: center;
    margin-top: 15px;
  }

  .oc_container {
    max-width: calc(100% - 15px);
  }

  .oc_btn.copyRefferalCode {
    margin-left: auto;
    margin-right: auto;
  }

  .logoDesktop {
    top: 80px;

    ul {
      li {
        font-size: 22px;
      }
    }
  }
}


@media (max-width: 520px) {
  .header_component_center {
    height: 100%;

    &::after {
      height: 51.6%;
    }

    &::before {
      height: 51.6%;
    }
  }
}

@media (max-width: 450px) {
  .buySell .buySellbox .oc_btn {
    display: block;
    width: 100%;
  }

  .buyOc .buyOcbox .oc_btn {
    display: block;
    width: 100%;
  }
}

@media (max-width: 390px) {
  .main {
    padding: 0px 2vw 0vw;
  }
}