/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/
.projects {
  margin: 0 0 60px;
}
.projects .card-glow {
  filter: drop-shadow(0px 1px 3px #50dbff);
}
@keyframes blinkers {
  0%, 100% {
    filter: drop-shadow(0px 0px 2px #5bd6f5) hue-rotate(40deg) brightness(1.5);
  }
  50% {
    filter: drop-shadow(0px 0px 2px rgba(80, 220, 255, 0.3490196078)) hue-rotate(40deg) brightness(0.6);
  }
}
.projects .blinkers {
  filter: drop-shadow(0px 0px 2px #50dbff) hue-rotate(40deg);
  animation: blinkers 1.5s linear infinite;
  transition: 0.7s;
}
.projects .sale-card.active_Class svg {
  filter: drop-shadow(0px 1px 3px #50dbff);
}

.countBox .countinbox {
  height: 100%;
  position: relative;
}
.countBox svg {
  display: block;
}
.countBox h3 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0px;
  color: #fff;
  text-shadow: 2px 2px 5px #50dbff;
}
.countBox p {
  font-weight: 500;
}

.col-sm-6 {
  padding: 15px;
}

.listBoxInner {
  padding: 0 10px;
  position: relative;
}
.listBoxInner .test:after {
  background-image: none !important;
  animation: none !important;
}
.listBoxInner .meter {
  /* box-sizing: content-box; */
  height: 30px;
  position: relative;
  background: rgba(34, 34, 34, 0.1333333333);
  padding: 6px;
  border-radius: 25px;
  box-shadow: inset 0px 0px 4px 3px rgb(207, 36, 91);
}
.listBoxInner .meter > span {
  display: block;
  height: 100%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  position: relative;
  overflow: hidden;
}
.listBoxInner .meter .animateline {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  background: rgb(207 36 91 / 60%);
  border-radius: 10px;
  box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3), inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  transition: 0.7s;
  animation: line_Animation 5s linear infinite;
}
@keyframes progress-anime {
  0% {
    transform: translate(0px, 0px) scale(1);
    opacity: 1;
    filter: drop-shadow(0px 0px 2px #36c0e7) brightness(1.5);
  }
  100% {
    transform: translate(12px, 0px) scale(1);
    opacity: 0;
    filter: drop-shadow(0px 0px 2px #36c0e7) brightness(2);
  }
}
.listBoxInner .meter .animateline:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /* background-image: url("../../../public/images/arrow.svg"); */
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="65" viewBox="0 0 48 65" fill="none"><path d="M12.412 1L1 11.2866L24.2765 32.4603L1 53.6227L12.412 64L47 32.4603L12.412 1Z" stroke="%23CF245B" stroke-width="1.5" stroke-linejoin="round"/></svg>');
  z-index: 1;
  background-size: 14px 18px;
  animation: move 2s linear infinite;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: hidden;
  filter: drop-shadow(0 0 1px #3af8ff) brightness(2);
  animation: progress-anime 1s linear infinite;
  transition: 0.5s;
}
.listBoxInner .animate {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
  z-index: 1;
  background-size: 50px 50px;
  animation: move 2s linear infinite;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: hidden;
}
.listBoxInner .profileInfo {
  align-items: center;
  justify-content: space-between;
}
.listBoxInner .profilePic {
  flex: 0 0 40px;
  /* max-width: 40px;
  height: 40px; */
  border-radius: 50%;
  padding: 0px;
  z-index: 10;
}
.listBoxInner .profileInfo ul li {
  display: none;
  text-align: center;
  font-weight: 800;
}
.listBoxInner .profileInfo ul li span {
  width: 8px;
  height: 8px;
  border-radius: 50px;
  display: inline-block;
  margin-right: 4px;
}
.listBoxInner .profileInfo ul li.upcoming {
  color: #d29813;
}
.listBoxInner .profileInfo ul li.saleLive {
  color: #10b981;
}
.listBoxInner .profileInfo ul li.saleEnded {
  color: red;
}
.listBoxInner .profileInfo ul li.active {
  display: inline-block;
}
/* .listBoxInner h2 {
  font-size: 22px;
  font-weight: 600;
  margin: 0;
  display: block;
} */
.listBoxInner .subtitle {
  @apply text-base font-semibold text-white block;
  /* font-size: 15px;
  font-weight: 600;
  color: #888;
  display: block; */
}
/* .listBoxInner h3 {
  font-size: 15px;
  font-weight: 600;
  margin: 0;
  display: block;
} */
.listBoxInner strong {
  /* margin: 5px 0px 10px; */
  @apply text-xl font-semibold text-secondary block;
}
.listBoxInner .progressBar {
  margin: 3px 0 0px;
  font-size: 15px;
}
.listBoxInner .progresLine {
  display: block;
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  background-color: rgba(72, 199, 116, 0.15);
  border-radius: 100px;
  height: 8px;
}
.listBoxInner .progresLine span {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(72, 199, 116);
  border-radius: 100px;
}
.listBoxInner .tableBox p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 400;
}
.listBoxInner .tableBox p span {
  font-size: 15px;
  font-weight: 600;
}
.listBoxInner .tableBox p.ttl {
  color: #888;
  font-size: 13px;
  margin: 0 0 15px;
  font-weight: 600;
}
.listBoxInner .tableBox p.ttl span {
  font-size: 13px;
}
.listBoxInner .dividerLine {
  @apply block w-full h-px bg-secondary;
  /* display: block;
  width: 100%;
  height: 1px;
  background-color: #50dbff;
  margin-top: 5px; */
}
.listBoxInner .saleTineView {
  align-items: center;
  margin: 0px;
  z-index: 2;
  position: relative;
}
.listBoxInner .saleTineView .timesSale {
  @apply m-0 grow-0 shrink-0 mr-4 p-0 text-base;
  flex-basis: calc(100% - 105px);
  max-width: calc(100% - 105px);
}
.listBoxInner .saleTineView .timesSale span {
  @apply inline-block py-1 px-2 text-xs bg-secondary bg-opacity-60 text-white mt-1 tracking-widest font-semibold rounded-full;
}
.listBoxInner .saleTineView .btnView {
  flex: 0 0 90px;
  max-width: 90px;
  @apply text-center bg-secondary-100 text-white py-2 px-1.5 uppercase tracking-wide rounded-lg transition-all duration-500;
  /* text-align: center;
  background-color: #133750;
  color: #ffffff; */
  text-decoration: none;
  /* padding: 7px 5px;
  border-radius: 5px; */
  /* text-transform: uppercase;
  letter-spacing: 0.5px; */
}
.listBoxInner .saleTineView .react-countdown {
  padding: 0px;
  margin: 0px;
  list-style: none;
}
.listBoxInner .saleTineView .react-countdown li {
  display: inline-block;
  margin-right: 4px;
}
.listBoxInner .saleTineView .react-countdown li::after {
  top: 5px;
  font-size: 14px;
  right: -7px;
}
.listBoxInner .saleTineView .react-countdown .digit {
  color: #01FFFF;
  margin: 5px 0px 10px;
  display: block;
  font-size: 14px;
  font-weight: bold;
}
.listBoxInner .saleTineView .react-countdown .text {
  display: none;
}
.listBoxInner:hover {
  @apply border-secondary;
  /* border-color: #01FFFF; */
}
.listBoxInner:hover .saleTineView .btnView {
  @apply bg-secondary text-white;
}
.listBoxInner:hover::before {
  opacity: 1;
  mix-blend-mode: unset;
}
.listBoxInner:hover::after {
  opacity: 1;
  mix-blend-mode: unset;
}
.listBoxInner.active {
  transition: all 0.2s ease-in-out;
  box-shadow: none;
  text-shadow: none;
}
@keyframes blinkOcPublicBrn {
  0% {
    border-color: #125d60;
    opacity: 1;
    box-shadow: none;
    text-shadow: none;
  }
  49% {
    border-color: #02ffff;
    color: #02ffff;
    text-shadow: -0.2rem -0.2rem 1rem #ffffff, 0.2rem 0.2rem 1rem #ffffff, 0 0 2rem #02ffff, 0 0 4rem #02ffff, 0 0 6rem #02ffff, 0 0 8rem #02ffff, 0 0 10rem #02ffff;
    box-shadow: 0 0 0.1rem #125d60, inset 0 0 0.1rem #125d60, 0 0 2rem #125d60, inset 0 0 0rem #125d60, 0 0 1rem #125d60, inset 0 0 1rem #02ffff;
  }
  50% {
    border-color: #125d60;
    opacity: 1;
    box-shadow: none;
    text-shadow: none;
  }
}
.listBoxInner.active .infoBox {
  text-shadow: none;
}

@media only screen and (max-width: 575px) {
  .projects .countBox .countinbox {
    height: auto;
  }
}
/* @media only screen and (max-width: 440px) {
  .projects .sale-card {
    height: 410px;
    width: 355px;
  }
} */
/* @media only screen and (max-width: 360px) {
  .projects .sale-card {
    height: 406px;
    width: 350px;
  }
} */
/*# sourceMappingURL=Projects.css.map */