@tailwind base;
@tailwind components;
@tailwind utilities;

a,button{
  @apply outline-none focus:outline-none focus:ring-0;
}

.headbar{
    @apply p-4 bg-primary border-2 border-solid border-secondary-100 rounded-3xl;
    box-shadow: 0 10px 20px rgb(32 40 118 / 75%);
}

.button {
  font-size: 16px;
  border-radius: 12px;
  background: linear-gradient(180deg, #222C7E 0%, #222C7E 66%, #222C7E 100%);
  color: #ffffff;
  border: none;
  padding: 2px;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
  
  .button span {
    border-radius: 10px;
    padding: 0.8em 1.3em;
    padding-right: 1.2em;
    text-shadow: 0px 0px 20px #4b4b4b;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 12px;
    color: inherit;
    transition: all 0.3s;
    background-color: #1F1156;
    background-image: radial-gradient(at 95% 89%, #202875 0px, transparent 50%), radial-gradient(at 0% 100%, #202672 0px, transparent 50%), radial-gradient(at 0% 0%, #221A62 0px, transparent 50%);
  }
  
  .button:hover span {
    background-color: #CF245B;
  }
  
  .button-overlay {
    position: absolute;
    inset: 0;
    pointer-events: none;
    background: repeating-conic-gradient(#221A62 0.0000001%, #202875 0.000104%) 60% 60% / 600% 600%;
    filter: opacity(10%) contrast(105%);
    -webkit-filter: opacity(10%) contrast(105%);
  }
  
  .button svg {
    width: 15px;
    height: 15px;
  }

  .section-padding{
    @apply py-12 md:py-28;
  }
  .spacer {
    height: 8px;
  }  