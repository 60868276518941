.buySell {
  margin: 0 auto 60px;
}
.buySell .buySellbox {
  height: 100%;
  position: relative;
}
.buySell .buySellbox .meter {
  height: 30px;
  position: relative;
  background: rgba(34, 34, 34, 0.1333333333);
  padding: 6px;
  border-radius: 25px;
  box-shadow: inset 0px 0px 4px 3px rgb(207, 36, 91);
}
.buySell .buySellbox .meter > span {
  display: block;
  height: 100%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  position: relative;
  overflow: hidden;
}
.buySell .buySellbox .meter .animateline {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  background: rgb(207 36 91 / 60%);
  border-radius: 10px;
  box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3), inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  transition: 0.7s;
  animation: line_Animation 5s linear infinite;
}
@keyframes progress-anime {
  0% {
    transform: translate(0px, 0px) scale(1);
    opacity: 1;
    filter: drop-shadow(0px 0px 2px #36c0e7) brightness(1.5);
  }
  100% {
    transform: translate(12px, 0px) scale(1);
    opacity: 0;
    filter: drop-shadow(0px 0px 2px #36c0e7) brightness(2);
  }
}
.buySell .buySellbox .meter .animateline:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /* background-image: url("../../../public/images/arrow.svg"); */
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="65" viewBox="0 0 48 65" fill="none"><path d="M12.412 1L1 11.2866L24.2765 32.4603L1 53.6227L12.412 64L47 32.4603L12.412 1Z" stroke="%23CF245B" stroke-width="1.5" stroke-linejoin="round"/></svg>');
  z-index: 1;
  background-size: 14px 18px;
  animation: move 2s linear infinite;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: hidden;
  filter: drop-shadow(0 0 1px #3af8ff) brightness(2);
  animation: progress-anime 1s linear infinite;
  transition: 0.5s;
}
.buySell .buySellbox .topttl {
  display: block;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 8px;
  text-align: right;
}
.buySell .buySellbox .fillBox {
  display: block;
  width: 100%;
  padding: 25px;
  background-color: rgba(255, 255, 255, 0.161);
  border: none;
  border-radius: 5px;
  margin-bottom: 8px;
  font-size: 20px;
  color: #ffffff;
  position: relative;
  overflow: hidden;
}
.buySell .buySellbox .fillBox span {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #02ffff;
}
.buySell .buySellbox .buttonGrp {
  text-align: center;
}
@media only screen and (max-width: 1280px) {
  .buySellbox::before {
    content: "";
    height: 100%;
    left: 12%;
    position: absolute;
    top: 0;
    background: linear-gradient(102deg, #163954, #062a3b);
    border: 1px solid #50dbff;
    border-radius: 14px;
    transform: scale(1.05) perspective(300px) rotateX(350deg);
    width: 80%;
    z-index: -1;
  }
  .buySellbox::after {
    content: "";
    height: 100%;
    left: 22%;
    position: absolute;
    top: 10%;
    background: linear-gradient(102deg, #163954, #062a3b);
    border-radius: 14px;
    transform: scale(1.05) perspective(300px) rotateX(350deg);
    width: 60%;
    z-index: -1;
    border-bottom: 2px solid #02ffff;
  }
}
@media only screen and (max-width: 767px) {
  .buySellbox::before {
    left: 2% !important;
    transform: scale(1.05) perspective(300px) rotateX(350deg);
    width: 95% !important;
  }
  .buySellbox::after {
    height: 100%;
    left: 10% !important;
    top: 10%;
    transform: scale(1.05) perspective(300px) rotateX(350deg);
    width: 80% !important;
  }
}/*# sourceMappingURL=BuySell.css.map */