/* .publicSale {
  margin: 0px 0px 100px;
} */
.publicSale .infobox {
  margin-bottom: 15px;
}
.publicSale .infobox h1 {
  margin: 0 0 30px;
  font-size: 48px;
  font-weight: bold;
  text-shadow: 1px 4px 0px #272568, -1px 3px 0px #283497;
  letter-spacing: 1px;
}
.publicSale .infobox ul {
  padding: 0px;
  margin: 0px 0px 30px;
  list-style: none;
}
.publicSale .infobox ul li {
  margin-bottom: 15px;
}
.publicSale .infobox h3 {
  font-size: 20px;
  margin: 0 0 10px;
}
.publicSale .infobox .copybox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #125d60;
  max-width: -moz-max-content;
  max-width: max-content;
  padding: 12px 15px;
  border-radius: 5px;
  color: #02ffff;
}
.publicSale .infobox .copybox p {
  margin: 0px;
}
.publicSale .infobox .copybox svg {
  font-size: 24px;
  margin-left: 20px;
  cursor: pointer;
  fill: #fff43c;
}
.publicSale .imageBox {
  text-align: center;
}
/* .publicSale .imageBox img {
  max-width: 500px;
  width: 100%;
} */

@media only screen and (max-width: 767px) {
  .publicSale {
    text-align: center;
    margin: 0px 0px 50px;
  }
  .publicSale .infobox .copybox {
    margin: 20px auto 0px;
  }
}
/* @media only screen and (min-width: 768px) {
  .imageBox img {
    margin-left: auto !important;
    margin-right: 0 !important;
  }
} */