/* .refDetails {
  margin: 0 auto 80px;
  max-width: calc(100% - 30px);
} */

.refDetailsList {
  padding: 30px;
  border-radius: 5px;
  position: relative;
}
.refDetailsList .table-responsive {
  overflow: auto;
  max-height: 413px;
}
.refDetailsList::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -15px;
  transform: translateY(-50%) rotate(180deg);
  width: 45px;
  height: 412px;
  max-height: 450px;
  background-image: url("../../../public/images/side header-frame.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left center;
}
.refDetailsList::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -15px;
  transform: translateY(-50%);
  width: 45px;
  height: 412px;
  max-height: 450px;
  background-image: url("../../../public/images/side header-frame.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left center;
}
.refDetailsList table {
  min-width: 700px;
}
.refDetailsList table th {
  background-color: #153953;
  padding: 15px;
  font-weight: 500;
  font-size: 18px;
}
.refDetailsList table td {
  font-size: 16px;
  padding: 10px 15px;
  border-bottom: 1px solid #153953;
}
.refDetailsList table tr:nth-last-child(1) td {
  border-bottom: none;
}

.claimBonus .buttonGrp button {
  color: #ffffff;
}

@media (max-width: 575px) {
  .balanceOverviewBox {
    padding: 20px;
  }
}/*# sourceMappingURL=RefDetails.css.map */